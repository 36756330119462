import { Typography, Box } from "@material-ui/core";
import format from "date-fns/format";
import { t } from "i18next";
import { amountFormatter } from "src/helpers";

const translatedColumns = (t) => {
  return [
    {
      field: "",
      title: t("recipient"),
      layout: (row) => <Typography>{row.recipient.nameSurname}</Typography>,
    },
    {
      field: "",
      title: t("service"),
      layout: (row) => (
        <Typography>
          {t(TypeList.find((i) => i.id === row.category?.id)?.name)}
        </Typography>
      ),
    },
    {
      field: "",
      title: t("type"),
      layout: (row) => (
        <Typography>
          {t(PackageType.find((i) => i.id === row.service?.id)?.name)}
        </Typography>
      ),
    },
    {
      field: "weight",
      title: `${t("weight")}/${t("hp_weight")}`,
      layout: (row: any) => (
        <Typography>
          <span>
            {row.weight || row.weight === 0 ? ` ${row.weight}գր.` : ""}
          </span>
          <span>{row.hpWeight ? ` /${row.hpWeight}գր.` : ""}</span>
        </Typography>
      ),
    },
    {
      field: "amount",
      title: `${t("amount")}/${t("hp_amount")}`,
      layout: (row) => (
        <Typography>
          <span>
            {row.amount || row.amount === 0
              ? amountFormatter(row.amount.toString())
              : ""}
          </span>
          <span>
            {row.hpAmount ? `/${amountFormatter(row.hpAmount.toString())}` : ""}
          </span>
        </Typography>
      ),
    },

    {
      field: "status",
      title: t("status"),
      layout: (row) => {
        const status = StatusList.find((i) => i.id === row.status?.id)?.name;
        return <Box>{t(status)}</Box>;
      },
    },
    {
      field: "organization",
      title: t("organization"),
    },
    {
      field: "note1",
      title: `${t("note")} 1`,
    },
    {
      field: "note2",
      title: `${t("note")} 2`,
    },
    {
      field: "",
      title: t("creation_date"),
      layout: (row) =>
        row.creationDate && (
          <Typography>
            {format(new Date(row.creationDate), "dd/MM/yyyy HH:mm")}
          </Typography>
        ),
    },
    {
      field: "",
      title: t("approve_date"),
      layout: (row) =>
        row.approvedDate && (
          <Typography>
            {format(new Date(row.approvedDate), "dd/MM/yyyy HH:mm")}
          </Typography>
        ),
    },
    {
      field: "createdByUser",
      title: t("creator"),
    },
  ];
};

const translatedExcelColumns = (t) => {
  return [
    {
      field: "",
      title: t("service"),
      layout: (row) => (
        <Typography>
          {TypeList.find((i) => i.id === row.category?.id)?.name}
        </Typography>
      ),
    },
    {
      field: "",
      title: t("type"),
      layout: (row) => (
        <Typography>
          {PackageType.find((i) => i.id === row.service?.id)?.name}
        </Typography>
      ),
    },
    {
      field: "weight",
      title: t("weight"),
      layout: (row) => (
        <Typography>
          {row.weight}
          {t("gram")}.
        </Typography>
      ),
    },
    {
      field: "amount",
      title: t("amount"),
      layout: (row) => (
        <Typography>
          {row.amount}
          {t("dram")}.
        </Typography>
      ),
    },
    {
      field: "",
      title: `${t("recipient")} (${t("name")} ${t("surname")})`,
      layout: (row) => <Typography>{row.recipient?.nameSurname}</Typography>,
    },
    {
      field: "",
      title: `${t("recipient")} (${t("address")})`,
      layout: (row) => <Typography>{row?.recipient?.address}</Typography>,
    },
    {
      field: "",
      title: `${t("return")} (${t("name")} ${t("surname")})`,
      layout: (row) => (
        <Typography>{row.returnSender?.nameSurname || "-"}</Typography>
      ),
    },
    {
      field: "",
      title: `${t("return")} (${t("address")})`,
      layout: (row) => (
        <Typography>{row?.returnSender?.address || "-"}</Typography>
      ),
    },
    {
      field: "note1",
      title: `${t("note")}_1`,
    },
    {
      field: "note2",
      title: `${t("note")}_2`,
    },
  ];
};

export enum ETypeList {
  SIMPLE = 1,
  ORDERED = 2,
  VALUED = 3,
  SIMPLE_TRACKED = 13,
}

export const RestrictedUsers = [
  "Test2HeadofEastponet@haypost.am",
  "Test2CentralN1DC@haypost.am",
  "test22@haypost.am",
];

export enum EAdditionalServices {
  NONE = 0,
  SIMPLE_NOTIFICATION = 1,
  ORDERED_NOTIFICATION = 2,
  PACKAGING = 3,
  PICK_UP = 4,
  POSTMEN_DELIVERY = 5,
  DELIVERY = 6,
  International_Notification = 15,
  DomesticSMS = 16,
  InternationalSMS = 17,
  InternationalSMSFree = 18,
}

export enum EPackageType {
  LETTER = 1,
  POSTCARD = 2,
  PACKAGE = 3,
  PARCEL = 4,
  SPRINT = 8,
  YES = 12,
}

const operatorTypes = [
  {
    id: 1,
    name: "Supervisor",
  },
  { id: 2, name: "Authorized" },
  { id: 3, name: "Standard" },
];

const AdditionalServices = [
  {
    id: 0,
    name: "Նշված չէ",
  },
  {
    id: 2,
    name: "Պատվիրված Ծանուցում",
  },
  {
    id: 3,
    name: "Փաթեթավորում",
  },
  {
    id: 4,
    name: "Տանից Ընդունում",
  },
  {
    id: 5,
    name: "Փոստատարի կողմից առաքում",
  },
  {
    id: 6,
    name: "Առաքում",
  },
];

const translatedAdditionalServices = {
  [EAdditionalServices.SIMPLE_NOTIFICATION]: "Հասարակ Ծանուցում",
  [EAdditionalServices.ORDERED_NOTIFICATION]: "Պատվիրված Ծանուցում",
  [EAdditionalServices.PACKAGING]: "Փաթեթավորում",
  [EAdditionalServices.PICK_UP]: "Տանից Ընդունում",
  [EAdditionalServices.POSTMEN_DELIVERY]: "Փոստատարի կողմից առաքում",
  [EAdditionalServices.DELIVERY]: "Առաքում",
  [EAdditionalServices.International_Notification]: "Միջազգային ծանուցում",
};

const TypeList = [
  {
    id: ETypeList.SIMPLE,
    name: "Simple",
  },
  {
    id: ETypeList.ORDERED,
    name: "Ordered",
  },
  {
    id: ETypeList.SIMPLE_TRACKED,
    name: "SimpleTracked",
  },
];

const categoryTypesOptions = {
  [ETypeList.SIMPLE]: "հասարակ",
  [ETypeList.ORDERED]: "Պատվիրված",
  [ETypeList.VALUED]: "Գնահատված",
  [ETypeList.SIMPLE_TRACKED]: "Հասարակ Հետագծվող",
};

export enum EServiceTypes {
  Letter = 1,
  Postcard = 2,
  Package = 3,
  Parcel = 4,
  Small_Bundle = 6,
  M_Bag = 7,
  Sprint = 8,
  EMS_Letter = 10,
  EMS_Parcel = 11,
  YES = 12,
}

export const translatedServiceTypes = {
  1: "Նամակ",
  2: "Բացիկ",
  3: "Փաթեթ",
  4: "Ծանրոց",
  6: "Մանր կապոց",
  7: "Մ Պարկ",
  8: "Սպրինտ",
  10: "EMS Նամակ",
  11: "EMS Ծանրոց",
  12: "YES",
};

export enum ECountriesList {
  ARMENIA = 11,
  RUSSIA = 180,
  MOSCOW = 260,
}

export enum EPackageTypesList {
  SEKOGRAM = 5,
  REVERT = 9,
}

const PackageType = [
  {
    id: 1,
    name: "Letter",
    maximalWeight: 2000,
    availableCategories: [
      ETypeList.SIMPLE,
      ETypeList.ORDERED,
      ETypeList.VALUED,
    ],
    availableAdditionalServices: {
      [ETypeList.SIMPLE]: [],
      [ETypeList.ORDERED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.SIMPLE_NOTIFICATION,
      ],
      [ETypeList.VALUED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.SIMPLE_NOTIFICATION,
      ],
    },
    hasCOD: false,
  },
  {
    id: 2,
    name: "Postcard",
    maximalWeight: 0,
    availableCategories: [ETypeList.SIMPLE, ETypeList.ORDERED],
    availableAdditionalServices: {
      [ETypeList.SIMPLE]: [],
      [ETypeList.ORDERED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.SIMPLE_NOTIFICATION,
      ],
    },
    hasCOD: false,
  },
  {
    id: 3,
    name: "Package",
    maximalWeight: 2000,
    availableCategories: [
      ETypeList.SIMPLE,
      ETypeList.ORDERED,
      ETypeList.VALUED,
    ],
    availableAdditionalServices: {
      [ETypeList.SIMPLE]: [
        EAdditionalServices.NONE,
        EAdditionalServices.PACKAGING,
      ],
      [ETypeList.ORDERED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.SIMPLE_NOTIFICATION,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.PACKAGING,
      ],
      [ETypeList.VALUED]: [],
    },
    hasCOD: true,
  },
  {
    id: 4,
    name: "Parcel",
    maximalWeight: 20000,
    availableCategories: [ETypeList.SIMPLE, ETypeList.VALUED],
    availableAdditionalServices: {
      [ETypeList.SIMPLE]: [
        EAdditionalServices.NONE,
        EAdditionalServices.SIMPLE_NOTIFICATION,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.PACKAGING,
      ],
      [ETypeList.VALUED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.SIMPLE_NOTIFICATION,
        EAdditionalServices.ORDERED_NOTIFICATION,
        EAdditionalServices.PACKAGING,
      ],
    },
    hasCOD: true,
  },
  {
    id: 6,
    name: "Small Bundle",
  },
  {
    id: 7,
    name: "M Bag",
  },
  {
    id: 8,
    name: "Sprint",
    maximalWeight: 2000,
    availableCategories: [ETypeList.SIMPLE],
    availableAdditionalServices: {
      [ETypeList.SIMPLE]: [],
    },
    hasCOD: true,
  },
  {
    id: 12,
    name: "YES",
    maximalWeight: 20000,
    availableCategories: [ETypeList.ORDERED],
    availableAdditionalServices: {
      [ETypeList.ORDERED]: [
        EAdditionalServices.NONE,
        EAdditionalServices.PICK_UP,
        EAdditionalServices.POSTMEN_DELIVERY,
        EAdditionalServices.DELIVERY,
      ],
    },
    hasCOD: true,
  },
  {
    defaultReturnDays: 14,
    id: 10,
    isActive: true,
    maxWeightLimit: 2000,
    minWeightLimit: 0,
    name: "EMS Letter",
  },
  {
    defaultReturnDays: 14,
    id: 11,
    isActive: true,
    maxWeightLimit: 31000,
    minWeightLimit: 0,
    name: "EMS Parcel",
  },
];

export enum EStatus {
  Created = 1,
  Successful = 3,
  Error = 4,
}

const StatusList = [
  {
    id: 1,
    name: "Created",
  },
  {
    id: 2,
    name: "InProcess",
  },
  {
    id: 3,
    name: "CoreCreated",
  },
  {
    id: 4,
    name: "Error",
  },
  {
    id: 6,
    name: "CorePaid",
  },
  {
    id: 7,
    name: "CoreRejected",
  },
  {
    id: 8,
    name: "CoreRevert",
  },
  {
    id: 9,
    name: "CoreReadyToReceive",
  },
  {
    id: 10,
    name: "CoreOutForDelivery",
  },
  {
    id: 11,
    name: "CoreReceived",
  },
  { id: 12, name: "CoreReturnToSender" },
  { id: 13, name: "CoreTimeToReturn" },
];

const AddressStatusList = [
  { label: "is_remembered" },
  { label: "company_address" },
  { label: "other_addresses" },
];

export enum EPrintOptions {
  DL = 1,
  C5 = 2,
  A4 = 3,
  CN07 = 4,
  C6 = 5,
  TAX_PERCUE = 6,
  CHEQUE = 7,
  OVERHEAD_PAYMENT_LANDSCAPE = 8,
  OVERHEAD_PAYMENT_PORTRAIT = 9,
  BARCODE = 10,
  CN22 = 11,
  CN23_CP72 = 12,
  CP71 = 13,
  EMS = 14,
}

const translatedConfigOptions = (t: any) => [
  {
    id: 1,
    key: "showSenderLogo",
    name: t("logo"),
  },
  {
    id: 2,
    key: "showReturnAddress",
    name: t("sender_return_sender_address"),
  },
  {
    id: 3,
    key: "showAdditionalNotes",
    name: `${t("additional_note")} 1`,
  },
  {
    id: 4,
    key: "showAdditionalNotes2",
    name: `${t("additional_note")} 2`,
  },
  {
    id: 5,
    key: "showReceiverAddress",
    name: t("recipient_info"),
  },
];

const translatedCODSelectOptions = (t: any) => {
  return [
    {
      id: EPrintOptions.OVERHEAD_PAYMENT_LANDSCAPE,
      name: t("COD_landscape"),
      height: "55mm",
      width: "80mm",
    },
    {
      id: EPrintOptions.OVERHEAD_PAYMENT_PORTRAIT,
      name: t("COD_portrait"),
      height: "75mm",
      width: "48mm",
    },
  ];
};

const translatedProvinceStates = [
  {
    id: 23,
    name: "Aragatsotn",
  },
  {
    id: 24,
    name: "Ararat",
  },
  // {
  //   id: 25,
  //   name: "Vaxarshapat",  
  // },
  {
    id: 26,
    name: "Gexarquniq",
  },
  {
    id: 27,
    name: "Yerevan",
  },
  {
    id: 28,
    name: "Lori",
  },
  {
    id: 29,
    name: "Kotayq",
  },
  {
    id: 30,
    name: "Shirak",
  },
  {
    id: 31,
    name: "Syuniq",
  },
  {
    id: 32,
    name: "Vayoc_dzor",
  },
  {
    id: 33,
    name: "Tavush",
  },
  {
    id: 34,
    name: "Arcax",
  },
  {
    id: 35,
    name: "Armavir",
  }
];

export {
  AddressStatusList,
  translatedColumns,
  translatedExcelColumns,
  operatorTypes,
  TypeList,
  PackageType,
  StatusList,
  AdditionalServices,
  translatedConfigOptions,
  translatedCODSelectOptions,
  categoryTypesOptions,
  translatedAdditionalServices,
  translatedProvinceStates,
};
